<template>
    <div class="container">
        <div class="crumb">
            <span>
                您当前所在位置：
            </span>
            <router-link to='/'>首页</router-link>
            <i class="iconfont icon-arrow-right"></i>
            <!-- <router-link to='/major'>{{name}}</router-link> -->
            <span>{{name}}</span>
            <!-- <router-link to='/climate'></router-link> -->
        </div>

        <Padding />

        <div class="card">
            <div class="sider">
                <div class="last-page">
                    {{name}}
                </div>

                <a class="item" v-for="item in category" :key="item.code" @click.prevent="setActive(item)"
                    :class="item.code === active.code ? 'active' : ''">
                    {{item.name}}
                </a>

            </div>

            <div class="content">
                <div class="header">
                    <div class="title">{{active.name}}</div>
                </div>
                <div class="body">
                    <div class="between item" v-for="item in list" :key="item.code" @click="openArticleDetail(item)">
                        <span>{{item.title}}</span>
                        <span class="date">{{ item.publish_at | timeTrans }}</span>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import article from '@/mixins/article'
import { fetchCategory, fetchList  } from '@/api/article'
export default {
    mixins: [article],
    data() {
        return {
            name: '',
            category: [],
            active: {},
            list: [],
            total: 0,
        }
    },

    watch: {
        active() {
            this.getList();
        }
    },

    mounted() {
        const { query } = this.$route;
        const { name } = query || {};
        this.name = name;
        fetchCategory().then(res => {
            this.list = res;
            if (Array.isArray(res)) {
                const obj = res.find(item => item.name === name)
                if (obj && Array.isArray(obj.children)) {
                    if (obj.children.length > 0) {
                        this.category = obj.children;
                        this.active = obj.children[0]
                    }
               }
            }
        })
    },
    methods: {
        setActive(item) {
            this.active = item;
        },
        getList() {
            fetchList({
                p: [{
                    category: this.active.code,
                    page: 1,
                    rows: 10,
                }]
            }).then(res => {
                if (res[0].data && Array.isArray(res[0].data.list)) {
                    this.list = res[0].data.list;
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>


.card {
    display: flex;
    padding: 12px;
    background-color: #fff;

    .sider {
        margin-right: 24px;

        .last-page {
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            width: 240px;
            height: 60px;
            padding: 11.5px 40px 0;
            display: block;
            background-image: url('../../assets/bg.png');
            background-repeat: no-repeat;
            margin-bottom: 24px;

        }

        .item {
            display: block;
            background-color: #f0f0f0;
            border-left: 5px solid #dddddd;
            height: 40px;
            line-height: 40px;
            width: 220px;
            box-sizing: border-box;
            padding-left: 24px;
            margin-left: 12px;
            cursor: pointer;

            &:hover {
                border-left-color: var(--theme-color);
                color: var(--theme-color);
            }
     
        }
                           .active {
                               border-left-color: var(--theme-color);
                               color: var(--theme-color);
                           }
    }

    .content {
        flex: 1;

        .header {
            border-bottom: 1px solid #e0e0e0;

            .title {
                border-bottom: 2px solid var(--theme-color);
                color: var(--theme-color);
                display: inline-block;
                padding: 8px 24px;
                font-size: 20px;
            }
        }

        .body {
            padding-top: 12px;

            .item {
                margin-bottom: 12px;
                padding-right: 12px;

                position: relative;
                left: 12px;
                cursor: pointer;

                &::before {
                    content: '';
                    position: absolute;
                    top: 8px;
                    left: -12px;
                    width: 5px;
                    height: 5px;
                    background-color: #999;
                    border-radius: 50%;
                }

                .date {
                    color: #999;
                    font-size: 13px;
                }
            }
        }


    }
}
</style>